import {
  Box,
  Button,
  Divider,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import PhoneCall from "../../Assets/icons/PhoneCall.svg";

const NavContacts = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: "10px", md: "0" },
      }}
    >
      <MuiLink href="tel:+8801968337755" height="24px" width="24px">
        <Box component="img" src={PhoneCall} height="100%" width="100%" />
      </MuiLink>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          borderColor: "#fff",
          marginX: { md: "13px" },
          display: { xs: "none", md: "block" },
        }}
      />
      <Box>
        <Typography variant="body2" color="textBodyWhite">
          Have any Question?
        </Typography>
        <Typography
          component={MuiLink}
          href="tel:+8801968337755"
          variant="h5"
          color="textHeadingWhite"
          sx={{
            textDecoration: "none !important",
          }}
        >
          +8801968337755
        </Typography>
      </Box>
      <Button
        variant="themed"
        color="button"
        onClick={() => navigate("/contact-us")}
        sx={{
          marginLeft: { md: "36px" },
          height: "32px",
          width: "135px",
        }}
      >
        Contact Us
      </Button>
    </Box>
  );
};

export default NavContacts;
