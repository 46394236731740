import { lazy } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { businessTypeIds } from "./DataSource/BusinessDetails/businessTypeDetails";
import MainLayout from "./Layouts/MainLayout";

// import required css
import "swiper/css";
import "swiper/css/pagination";

// lazy imports
const Home = lazy(() => import("./Pages/Home"));
const Events = lazy(() => import("./Pages/Events"));
const Galary = lazy(() => import("./Pages/Galary"));
const AboutUs = lazy(() => import("./Pages/AboutUs"));
const ContactUs = lazy(() => import("./Pages/ContactUs"));
const BusinessDetails = lazy(() => import("./Pages/BusinessDetails"));

export default function App() {
  const businessTypeRoutes = businessTypeIds.map(([item]) => {
    return {
      path: `/${item}`,
      element: <BusinessDetails />,
    };
  });

  const routes = createBrowserRouter([
    {
      path: "",
      element: <MainLayout />,
      children: [
        // landing page
        {
          path: "",
          element: <Home />,
        },

        // regular pages
        {
          path: "/about-us",
          element: <AboutUs />,
        },
        {
          path: "/galary",
          element: <Galary />,
        },
        {
          path: "/events",
          element: <Events />,
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },

        // business details
        ...businessTypeRoutes,
      ],
    },
    // fallback to landing page
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  return <RouterProvider router={routes} />;
}
