import { Box, Button, Menu, MenuItem } from "@mui/material";
import React, { Fragment, useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { businessTypeIds } from "../../DataSource/BusinessDetails/businessTypeDetails";

const NavLinks = ({ setExpanded = () => null }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const navLinks = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "About Us",
      path: "/about-us",
    },
    {
      title: "Our Business",
      subPath: businessTypeIds.map(([typeId, typeData]) => {
        return {
          title: typeData.title,
          path: `/${typeId}`,
        };
      }),
    },
    {
      title: "Gallery",
      path: "/galary",
    },
    {
      title: "Events",
      path: "/events",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: "5px", md: "30px" },
        marginX: { lg: "40px" },
        variant: "h5",
        flexGrow: 1,
      }}
    >
      {navLinks.map(({ title, path = null, subPath = null }, idx) => {
        return (
          <Fragment key={`navlinks-${idx}`}>
            {Boolean(subPath) ? (
              <Fragment>
                <Button
                  variant="text"
                  onClick={(event) => {
                    Boolean(path)
                      ? navigate(path)
                      : setAnchorEl(event.currentTarget);
                  }}
                  sx={{
                    color: "#fff",
                    padding: "0 !important",
                    paddingBottom: "3px !important",
                    textTransform: "none",
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontWeight: 500,
                    display: "flex",
                    gap: "6px",
                    minWidth: "unset",
                    borderRadius: 0,
                    borderBottom: "3px solid",
                    borderColor: businessTypeIds
                      .map(([typeId]) => `/${typeId}`)
                      .includes(pathname)
                      ? "accent.main"
                      : "transparent",
                  }}
                >
                  {title}
                  <IoChevronDown />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  PaperProps={{
                    sx: {
                      bgcolor: "primary.main",
                      boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
                      "& .MuiList-root": {
                        p: "0 !important",
                        px: "10 !important",
                        background: "rgba(255, 255, 255, 0.3)",
                        color: "white",
                      },
                    },
                  }}
                >
                  {subPath.map(({ path, title }, idx) => {
                    return (
                      <MenuItem
                        key={`menu-sub-${idx}`}
                        onClick={() => {
                          navigate(path);
                          setAnchorEl(null);
                          setExpanded(false);
                        }}
                        sx={{
                          borderBottom: Boolean(subPath.length !== idx + 1)
                            ? "1px solid"
                            : "none",
                        }}
                      >
                        {title}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Fragment>
            ) : (
              <Button
                key={`navlinks-${idx}`}
                variant="text"
                onClick={(event) => {
                  Boolean(path)
                    ? navigate(path)
                    : setAnchorEl(event.currentTarget);
                  setExpanded(false);
                }}
                sx={{
                  color: "#fff",
                  padding: "0 !important",
                  paddingBottom: "3px !important",
                  textTransform: "none",
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontWeight: 500,
                  display: "flex",
                  gap: "6px",
                  minWidth: "unset",
                  borderRadius: 0,
                  borderBottom: "3px solid",
                  borderColor:
                    pathname === path ? "accent.main" : "transparent",
                }}
              >
                {title}
              </Button>
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default NavLinks;
