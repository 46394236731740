import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { ReactComponent as BrandLogoFooter } from "../../Assets/images/BrandLogoFooter.svg";

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        color: "#FFF",
      }}
    >
      <Container
        sx={{
          paddingY: "50px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100px", md: "200px" },
            height: { xs: "auto", md: "110px" },
          }}
        >
          <BrandLogoFooter width="100%" height="100%" />
        </Box>
        <Grid
          container
          sx={{ marginTop: { xs: 0, md: "40px" }, marginBottom: "80px" }}
          spacing="50px"
        >
          <Grid item xs={12} md={3} sx={{ mr: "20px" }}>
            <Typography
              variant="body2"
              color="textBodyWhite"
              lineHeight="30.5px"
            >
              Rongdhanu Group is one of the largest conglomerates of Bangladesh.
              The Rongdhanu Group has started its journey in 2008 as a real
              estate company.
            </Typography>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <Typography
              variant="h5"
              color="textHeadingWhite"
              sx={{
                paddingBottom: "10px",
              }}
            >
              Visit Us
            </Typography>
            <Typography variant="body2" color="textBodyWhite" lineHeight="30px">
              Business Point,
              <br /> Plot - 77-78,
              <br /> Road - 3 & 4,
              <br /> Dhaka - 1229, Bangladesh.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              color="textHeadingWhite"
              sx={{
                paddingBottom: "10px",
              }}
            >
              Contact Us
            </Typography>
            <Typography
              component={Link}
              href="tel:+8801968337755"
              variant="body2"
              color="textBodyWhite"
              lineHeight="30px"
            >
              +8801968337755
            </Typography>
            <Typography
              component={Link}
              href="mailto:info@rongdhanugroup.com"
              variant="body2"
              color="textBodyWhite"
              lineHeight="30px"
            >
              info@rongdhanugroup.com
            </Typography>
            <Box
              sx={{
                "& svg": {
                  color: "#fff",
                },
                "& button": {
                  padding: 0,
                },

                display: "flex",
                gap: "25px",
                marginTop: "30px",
              }}
            >
              <IconButton>
                <BsFacebook />
              </IconButton>
              <IconButton>
                <BsInstagram />
              </IconButton>
              <IconButton>
                <BsTwitter />
              </IconButton>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h5"
              color="textHeadingWhite"
              sx={{
                paddingBottom: "10px",
              }}
            >
              Newsletter Sign-up
            </Typography>
            <Typography variant="body2" color="textBodyWhite" lineHeight="10px">
              For News & Special Offers
            </Typography>

            <TextField
              variant="outlined"
              size="small"
              type="email"
              placeholder="Enter your email ID"
              sx={{
                border: "1px solid #fff",
                borderRadius: "5px",
                width: 1,
                marginTop: "25px",
                marginBottom: "15px",
                fontSize: "16px",
                "& fieldset": {
                  border: 0,
                },
                "& input": {
                  color: "#fff !important",
                  height: "40px",
                  paddingY: "0",
                },
              }}
            />
            <Button
              variant="themed"
              sx={{
                width: "115px",
                height: "28px",
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: "#fff" }} />
        <Box
          sx={{
            marginTop: "40px",
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            alignItems: { xs: "start", md: "center" },
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Typography variant="body2" color="textBodyWhite">
            © All rights reserved to © Rongdhanu Group 2022.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Typography variant="body2" color="textBodyWhite">
              Privacy Policy
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "#fff" }}
            />
            <Typography variant="body2" color="textBodyWhite">
              Terms & Conditions
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
