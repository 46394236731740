import { Box, Collapse, Container, IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import React, { useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import BrandLogo from "../../Assets/images/BrandLogo.svg";
import NavContacts from "./NavContacts";
import NavLinks from "./NavLinks";

export default function Header() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: "none",
      }}
    >
      <Container
        sx={{
          marginY: "30px",
          paddingX: { md: "0 !important" },
        }}
      >
        <Toolbar
          sx={{
            background: "rgba(255, 255, 255, 0.2)",
            boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
            paddingX: { lg: "72px !important" },
            paddingY: "20px !important",
            justifyContent: "space-between",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: { xs: 1, lg: "auto" },
              justifyContent: "space-between",
            }}
          >
            {/* brand logo */}
            <Box
              component="img"
              src={BrandLogo}
              width="90px"
              height="50px"
              onClick={() => navigate("/")}
              sx={{
                cursor: "pointer",
              }}
            />

            <IconButton
              sx={{
                display: { xs: "block", lg: "none" },
                bgcolor: expanded ? "#E8A125 !important" : "unset",
                width: "47px",
                height: "47px",
              }}
              onClick={() => setExpanded(!expanded)}
            >
              <HiMenuAlt1 style={{ color: "white", fontSize: "30px" }} />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              justifyContent: "space-between",
              flexGrow: 1,
            }}
          >
            {/* nav links */}
            <NavLinks />

            {/* contact info */}
            <NavContacts />
          </Box>

          <Collapse
            in={expanded}
            sx={{
              display: { xs: "block", lg: "none" },
              "& .MuiCollapse-wrapperInner": {
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              },
            }}
          >
            {/* nav links */}
            <NavLinks setExpanded={setExpanded} />

            {/* contact info */}
            <NavContacts />
          </Collapse>

          {/* end of header links */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
