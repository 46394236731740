import ArabikaCoffee0 from "../../Assets/images/business/ArabikaCoffee/0-min.webp";
import ArabikaCoffee1 from "../../Assets/images/business/ArabikaCoffee/1-min.webp";
import ArabikaCoffee2 from "../../Assets/images/business/ArabikaCoffee/2-min.webp";
import ArabikaCoffee3 from "../../Assets/images/business/ArabikaCoffee/3-min.webp";
import ArabikaCoffee4 from "../../Assets/images/business/ArabikaCoffee/4-min.webp";
import ArabikaCoffee5 from "../../Assets/images/business/ArabikaCoffee/5-min.webp";
import BazarSharabela0 from "../../Assets/images/business/BazarSharabela/0-min.webp";
import BazarSharabela1 from "../../Assets/images/business/BazarSharabela/1-min.webp";
import BazarSharabela2 from "../../Assets/images/business/BazarSharabela/2-min.webp";
import BazarSharabela3 from "../../Assets/images/business/BazarSharabela/3-min.webp";
import BazarSharabela4 from "../../Assets/images/business/BazarSharabela/4-min.webp";
import MehediMart0 from "../../Assets/images/business/MehediMart/0-min.webp";
import MehediMart1 from "../../Assets/images/business/MehediMart/1-min.webp";
import MehediMart2 from "../../Assets/images/business/MehediMart/2-min.webp";
import MehediMart3 from "../../Assets/images/business/MehediMart/3-min.webp";
import MehediMart4 from "../../Assets/images/business/MehediMart/4-min.webp";
import RGAgro0 from "../../Assets/images/business/RGAgro/0-min.webp";
import RGAgro1 from "../../Assets/images/business/RGAgro/1-min.webp";
import RGBuilders0 from "../../Assets/images/business/RGBuilders/0-min.webp";
import RGBuilders1 from "../../Assets/images/business/RGBuilders/1-min.webp";
import RGBuilders2 from "../../Assets/images/business/RGBuilders/2-min.webp";
import RGBuilders3 from "../../Assets/images/business/RGBuilders/3-min.webp";
import RGBuilders4 from "../../Assets/images/business/RGBuilders/4-min.webp";
import RGHolding0 from "../../Assets/images/business/RGHoldings/0-min.webp";
import RGHolding1 from "../../Assets/images/business/RGHoldings/1-min.webp";
import RGHolding2 from "../../Assets/images/business/RGHoldings/2-min.webp";
import RGHolding3 from "../../Assets/images/business/RGHoldings/3-min.webp";
import RGHospitality0 from "../../Assets/images/business/RGHospitality/0-min.webp";
import RGHospitality1 from "../../Assets/images/business/RGHospitality/1-min.webp";
import RGHospitality2 from "../../Assets/images/business/RGHospitality/2-min.webp";
import RGHospitality3 from "../../Assets/images/business/RGHospitality/3-min.webp";
import RGHospitality4 from "../../Assets/images/business/RGHospitality/4-min.webp";
import RGLifestyle0 from "../../Assets/images/business/RGLifestyle/0-min.webp";
import RGLifestyle1 from "../../Assets/images/business/RGLifestyle/1-min.webp";
import RGLifestyle2 from "../../Assets/images/business/RGLifestyle/2-min.webp";
import RGLifestyle3 from "../../Assets/images/business/RGLifestyle/3-min.webp";
import RGMultimedia0 from "../../Assets/images/business/RGMultimedia/0-min.webp";
import RGMultimedia1 from "../../Assets/images/business/RGMultimedia/1-min.webp";
import RGMultimedia2 from "../../Assets/images/business/RGMultimedia/2-min.webp";
import RGMultimedia3 from "../../Assets/images/business/RGMultimedia/3-min.webp";
import RGMultimedia4 from "../../Assets/images/business/RGMultimedia/4-min.webp";
import RGRefuelingStationCNG0 from "../../Assets/images/business/RGRefuelingStationCNG/0-min.webp";
import RGRefuelingStationCNG1 from "../../Assets/images/business/RGRefuelingStationCNG/1-min.webp";
import RGRefuelingStationCNG2 from "../../Assets/images/business/RGRefuelingStationCNG/2-min.webp";
import RGRefuelingStationCNG3 from "../../Assets/images/business/RGRefuelingStationCNG/3-min.webp";
import RGRefuelingStationCNG4 from "../../Assets/images/business/RGRefuelingStationCNG/4-min.webp";
import RGRefuelingStationCNG5 from "../../Assets/images/business/RGRefuelingStationCNG/5-min.webp";
import RIT0 from "../../Assets/images/business/RIT/0-min.webp";
import RIT1 from "../../Assets/images/business/RIT/1-min.webp";
import RIT2 from "../../Assets/images/business/RIT/2-min.webp";
import RIT3 from "../../Assets/images/business/RIT/3-min.webp";

const businessTypeDetails = {
  "rg-builders": {
    title: "RG Builders",
    estTitle: "EST 1988",
    website: "https://rongdhanubuilders.com",
    description:
      "Rongdhanu Builders is a renowned real estate company in Bangladesh. It was established in  2008. It has experienced massive success within a concise time. It has done some mega projects in different locations in the country. Some projects are-Bashundhara Complete Land Procurement & Development, Jolshiri Complete Land Procurement & Development, and Police Housing (POHS). And with its goodwill and commitment, from its inception, it is doing business with Bashundhara Group, one of the largest groups in Bangladesh with name & fame.",
    images: [
      {
        imgSrc: RGBuilders0,
        imgAlt: "RGBuilders0",
      },
      {
        imgSrc: RGBuilders1,
        imgAlt: "RGBuilders1",
      },
      {
        imgSrc: RGBuilders2,
        imgAlt: "RGBuilders2",
      },
      {
        imgSrc: RGBuilders3,
        imgAlt: "RGBuilders3",
      },
      {
        imgSrc: RGBuilders4,
        imgAlt: "RGBuilders4",
      },
    ],
    works: {
      workData: [
        {
          header: "Project 1",
          bullets: [
            "Bashundhara Complete Land Procurement & Development",
            "7000 acre",
          ],
        },
        {
          header: "Project 2",
          bullets: [
            "Jolshiri Complete Land Procurement & Development",
            "1000 acre",
          ],
        },
        {
          header: "Project 3",
          bullets: ["Police Housing (POHS)", "800 acre"],
        },
      ],
    },
  },
  "rg-refueling-station-cng": {
    title: "RG Refueling Station & CNG",
    estTitle: "EST 2010",
    description:
      "RG Refueling Station & CNG is a sister concern of Rongdhanu Group, which is eager to ensure standards of quality, safety, and workforce health. We are determined to develop a highly skilled and qualified technical workforce prepared to handle all maintenance and refueling work associated with natural gas power vehicles, the fuel of this era to be affordable, reliable, and environmentally friendly.",
    images: [
      {
        imgSrc: RGRefuelingStationCNG0,
        imgAlt: "RGRefuelingStationCNG0",
      },
      {
        imgSrc: RGRefuelingStationCNG1,
        imgAlt: "RGRefuelingStationCNG1",
      },
      {
        imgSrc: RGRefuelingStationCNG2,
        imgAlt: "RGRefuelingStationCNG2",
      },
      {
        imgSrc: RGRefuelingStationCNG3,
        imgAlt: "RGRefuelingStationCNG3",
      },
      {
        imgSrc: RGRefuelingStationCNG4,
        imgAlt: "RGRefuelingStationCNG4",
      },
      {
        imgSrc: RGRefuelingStationCNG5,
        imgAlt: "RGRefuelingStationCNG5",
      },
    ],
    works: {
      workData: [
        {
          header: "Established",
          bullets: ["2010"],
        },
        {
          header: "Location",
          bullets: ["Bulta Narayanganj"],
        },
        // {
        //   header: "Estimated Worth",
        //   bullets: ["৳ 100 Cr / $ 10 Million"],
        // },
      ],
    },
  },
  "mehedi-mart": {
    title: "Mehedi Mart",
    estTitle: "EST 2012",
    website: "https://www.mehedimart.com.bd",
    description:
      "Mehedi Mart Shopping Center is an international standard shopping mall located at the very prestigious area, Bashundhara of the capital city of Bangladesh, Dhaka. It was established in 2002 having multi facilities. One can fulfill his all necessary demand from this mall. So far it is the only one shopping mall with almost all facilities under one umbrella. Grocery shop, Garments & cosmetics item, Gold shop, Electrical and electronics item, Antics shop, Saloor (both man &woman), Gym (both man & woman), Restaurant with roof top facilities etc brings the mall in a high level. That’s why the people love it very much. A good number of foreign customers also gather here to meet their daily need",
    images: [
      {
        imgSrc: MehediMart0,
        imgAlt: "MehediMart0",
      },
      {
        imgSrc: MehediMart1,
        imgAlt: "MehediMart1",
      },
      {
        imgSrc: MehediMart2,
        imgAlt: "MehediMart2",
      },
      {
        imgSrc: MehediMart3,
        imgAlt: "MehediMart3",
      },
      {
        imgSrc: MehediMart4,
        imgAlt: "MehediMart4",
      },
    ],
    works: {
      workData: [
        {
          header: "Established",
          bullets: ["2012"],
        },
        // {
        //   header: "Estimated Cost of Project",
        //   bullets: ["৳ 60 Cr/ $ 6 Million"],
        // },
        // {
        //   header: "Revenue till 2021",
        //   bullets: ["৳ 900 Cr/ $ 90 Million"],
        // },
      ],
    },
  },
  "rg-agro": {
    title: "RG Agro",
    estTitle: "EST 2014",
    website: "https://rongdhanuagro.com",
    description:
      "Rongdhanu Agro & Beverage Ltd. is established as a private company limited by shares on 2013. This project is engaged in fish culturing, dairy farms, animal reproduction and agricultural activities. It is culturing and producing huge quantity fish to meet the demand of fish in local market. The company has planned to export frozen fish to abroad in near future. Other than meat, milk, food grains are also produced to meet the local demand from this company. Growing population, favorable demographics, rising income levels, increasing urbanization and growing numbers of women in the work force are contributing on regular basis to increase the production capacity of this sector.",
    images: [
      {
        imgSrc: RGAgro0,
        imgAlt: "RGAgro0",
      },
      {
        imgSrc: RGAgro1,
        imgAlt: "RGAgro1",
      },
    ],
    works: {
      workData: [
        {
          header: "Established",
          bullets: ["2014"],
        },
        // {
        //   header: "Estimated Worth",
        //   bullets: ["৳ 400 Cr / $ 40 Million"],
        // },
      ],
    },
  },
  "bazar-sharabela": {
    title: "Bazar Sharabela",
    estTitle: "EST 2016",
    description:
      "Bazar Sharabela is a renowned mall in Rupayon Shopping Square (beside Bashundhara Headquarters-1) in Dhaka, Bangladesh. It was founded in 2021 and had a variety of facilities. Customers can get all of their daily life products under the same roof. Grocery items, fresh fruits, fishes, vegetables, and other amenities raise the mall's profile. A vast number of consumers come here to satisfy their everyday needs.",
    images: [
      {
        imgSrc: BazarSharabela0,
        imgAlt: "BazarSharabela0",
      },
      {
        imgSrc: BazarSharabela1,
        imgAlt: "BazarSharabela1",
      },
      {
        imgSrc: BazarSharabela2,
        imgAlt: "BazarSharabela2",
      },
      {
        imgSrc: BazarSharabela3,
        imgAlt: "BazarSharabela3",
      },
      {
        imgSrc: BazarSharabela4,
        imgAlt: "BazarSharabela4",
      },
    ],
    works: {
      workData: [
        {
          header: "Established",
          bullets: ["2016"],
        },
        // {
        //   header: "Estimated Worth",
        //   bullets: ["৳ 60 Cr / $ 6 Million"],
        // },
      ],
    },
  },
  "arabika-coffee": {
    title: "Arabika Coffee",
    estTitle: "EST 2018",
    website: "https://www.arabika.com.bd",
    description:
      "Start your day with our freshly roasted and ground coffee at its best. Be it a chilling morning of the winter or scorching heat of the summer or maybe a completely dull day during monsoon when its pouring outside all day, come in sit down and enjoy a relaxing coffee and have it your way, hot or cold.",
    images: [
      {
        imgSrc: ArabikaCoffee0,
        imgAlt: "ArabikaCoffee0",
      },
      {
        imgSrc: ArabikaCoffee1,
        imgAlt: "ArabikaCoffee1",
      },
      {
        imgSrc: ArabikaCoffee2,
        imgAlt: "ArabikaCoffee2",
      },
      {
        imgSrc: ArabikaCoffee3,
        imgAlt: "ArabikaCoffee3",
      },
      {
        imgSrc: ArabikaCoffee4,
        imgAlt: "ArabikaCoffee4",
      },
      {
        imgSrc: ArabikaCoffee5,
        imgAlt: "ArabikaCoffee5",
      },
    ],
    works: {
      workData: [
        {
          header: "Established",
          bullets: ["2018"],
        },
        {
          header: "Outlets",
          bullets: ["7 Branches"],
        },
        // {
        //   header: "Estimated Worth",
        //   bullets: ["৳ 15 Cr / $ 1.5 Million"],
        // },
      ],
    },
  },
  rit: {
    title: "RIT",
    estTitle: "EST 2018",
    description:
      "Since 2022, RIT has achieved a lot with its projects Agun Tech, Puku Tech, and Thunderbolt. We work to meet our client's expectations and turn them into reality by thinking of the websites and software of their dreams.",
    images: [
      {
        imgSrc: RIT0,
        imgAlt: "RIT0",
      },
      {
        imgSrc: RIT1,
        imgAlt: "RIT1",
      },
      {
        imgSrc: RIT2,
        imgAlt: "RIT2",
      },
      {
        imgSrc: RIT3,
        imgAlt: "RIT3",
      },
    ],
    works: {
      workData: [
        {
          header: "Agun Tech",
          bullets: ["ISP"],
        },
        {
          header: "Puku Tech",
          bullets: ["ERP"],
        },
      ],
    },
  },
  "rg-lifestyle": {
    title: "RG Lifestyle",
    estTitle: "EST 2020",
    description:
      "Rongdhanu Lifestyle is a subsidiary of the Rongdhanu Group, known for its unique and stylish clothing and fashion accessories. It was established in 2021. And in a very short period, it has earned the confidence of its customers by providing high-quality products.",
    images: [
      {
        imgSrc: RGLifestyle0,
        imgAlt: "RGLifestyle0",
      },
      {
        imgSrc: RGLifestyle1,
        imgAlt: "RGLifestyle1",
      },
      {
        imgSrc: RGLifestyle2,
        imgAlt: "RGLifestyle2",
      },
      {
        imgSrc: RGLifestyle3,
        imgAlt: "RGLifestyle3",
      },
    ],
    works: {
      workData: [
        {
          header: "Established",
          bullets: ["2020"],
        },
        {
          header: "Outlets",
          bullets: ["2 Branches"],
        },
        // {
        //   header: "Estimated Worth",
        //   bullets: ["৳ 20 Cr / $ 2 Million"],
        // },
      ],
    },
  },
  "rg-multimedia": {
    title: "RG Multimedia",
    estTitle: "EST 2018",
    description:
      "RG multimedia, established in 2022, is a Bangladesh-based multilevel marketing company that owns a newspaper and television channel. Green TV Limited is their broadcast media, and  Protidiner Bangladesh is their print media platform.",
    images: [
      {
        imgSrc: RGMultimedia0,
        imgAlt: "RGMultimedia0",
      },
      {
        imgSrc: RGMultimedia1,
        imgAlt: "RGMultimedia1",
      },
      {
        imgSrc: RGMultimedia2,
        imgAlt: "RGMultimedia2",
      },
      {
        imgSrc: RGMultimedia3,
        imgAlt: "RGMultimedia3",
      },
      {
        imgSrc: RGMultimedia4,
        imgAlt: "RGMultimedia4",
      },
    ],
    works: {
      workData: [
        {
          header: "GREEN TV",
          bullets: [
            "Broadcast Media (Yet to be launched)",
            // "Estimated Cost : ৳ 100 Cr / $ 10 Million",
          ],
        },
        {
          header: "Protidiner Bangladesh",
          bullets: [
            "Print Media",
            // "Estimated Cost: ৳ 50 Cr / $ 5 Million"
          ],
        },
      ],
    },
  },
  "rg-hospitality": {
    title: "RG Hospitality Services",
    estTitle: "EST 2021",
    description:
      "Rongdhanu group introduced its hospitality services in 2022. In a very short period of time, the Rongdhanu group has undertaken various projects. Some of its renowned projects are Hotel Taj, Ramadas, Unique Regency Banani, and Remedy Hospital. And it's about to grow in the hospitality industry as well in a concise period of time.",
    images: [
      {
        imgSrc: RGHospitality0,
        imgAlt: "RGHospitality0",
      },
      {
        imgSrc: RGHospitality1,
        imgAlt: "RGHospitality1",
      },
      {
        imgSrc: RGHospitality2,
        imgAlt: "RGHospitality2",
      },
      {
        imgSrc: RGHospitality3,
        imgAlt: "RGHospitality3",
      },
      {
        imgSrc: RGHospitality4,
        imgAlt: "RGHospitality4",
      },
    ],
    works: {
      isSlider: true,
      workData: [
        {
          sliderNo: 1,
          sliderData: [
            {
              header: "Project 1",
              bullets: [
                "Hotel Taj (Under Construction)",
                // "Estimated Cost: ৳ 4000 Cr/ $ 400 Million",
                "Completion Date: 4 Years",
              ],
            },
            {
              header: "Project 2",
              bullets: [
                "Ramada’s (Cox’s Bazar Opening on November 2022)",
                // "Estimated Cost: ৳ 600 Cr/ $ 60 Million",
              ],
            },
            {
              header: "Project 3",
              bullets: [
                "Unique Regency Banani (Opening on November 2022)",
                // "Estimated Cost:  ৳ 100 Cr/ $ 10 Million",
              ],
            },
          ],
        },
        {
          sliderNo: 2,
          sliderData: [
            {
              header: "Project 4",
              bullets: [
                "Hospital (Under Construction)",
                // "Estimated Cost:  ৳ 270 Cr/ $ 27 Million",
              ],
            },
            {
              header: "Project 5",
              bullets: [
                "Best Western Premium (Inani)",
                // "Estimated Cost:  ৳ 700Cr/ $ 70 Million",
              ],
            },
          ],
        },
      ],
    },
  },
  "rg-holding": {
    title: "RG Holding",
    estTitle: "EST 2022",
    description:
      "Rongdhanu Holding (Pvt) Ltd, a sister concern of Rongdhanu Group was incorporated in 2008 as a land developer, Land buyer-seller and involve with building construction. From the time of its establishment until now this company is serving the country. The company headed and managed by a group of well reputed, energetic and young entrepreneurs in the capital city of Dhaka in various locations. At present living accommodation is a major problem in this city. To solve this problem Rongdhanu has taken a large numbers projects to build with multi-storied apartments building. Moreover, it is working sincerely to retain the position with professionalism at every step and efficiency at every move with meticulous care at every step and unfailing attention to every detail. This company is also maintaining international standard. Through its continuous support and sincerity to its customers for the last 9 years the company has become one of the leading companies in Dhaka. Due its goodwill and commitment, from the incepit is doing business with Bashundhara Group, one of the largest groups in Bangladesh with name & fame.",
    images: [
      {
        imgSrc: RGHolding0,
        imgAlt: "RGHolding0",
      },
      {
        imgSrc: RGHolding1,
        imgAlt: "RGHolding1",
      },
      {
        imgSrc: RGHolding2,
        imgAlt: "RGHolding2",
      },
      {
        imgSrc: RGHolding3,
        imgAlt: "RGHolding3",
      },
    ],
    works: {
      // isSlider: true,
      workData: [
        {
          header: "Project 1",
          bullets: [
            "Ramada’s (Cox’s Bazar Opening on November 2022)",
            // "Estimated Cost: ৳ 600 Cr/ $ 60 Million",
          ],
        },
        {
          header: "Project 2",
          bullets: [
            "100,000 SQFT in Jamuna Future Park",
            // "৳ 1000 Cr / $100 Million",
          ],
        },
        {
          header: "Project 3",
          bullets: [
            "1Completed Projects on Rent (7 x 10 Stories Apartment Buildings in Dhaka)",
          ],
        },
      ],
      // workData: [
      //   {
      //     sliderNo: 1,
      //     sliderData: [
      //       {
      //         header: "Project 1",
      //         bullets: [
      //           "Ramada’s (Cox’s Bazar Opening on November 2022)",
      //           "Estimated Cost: ৳ 600 Cr/ $ 60 Million",
      //         ],
      //       },
      //       {
      //         header: "Project 2",
      //         bullets: [
      //           "100,000 SQFT in Jamuna Future Park",
      //           "৳ 1000 Cr / $100 Million",
      //         ],
      //       },
      //       {
      //         header: "Project 3",
      //         bullets: [
      //           "1Completed Projects on Rent (7 x 10 Stories Apartment Buildings in Dhaka)",
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     sliderNo: 2,
      //     sliderData: [
      //       {
      //         header: "Project 1",
      //         bullets: [
      //           "Ramada’s (Cox’s Bazar Opening on November 2022)",
      //           "Estimated Cost: ৳ 600 Cr/ $ 60 Million",
      //         ],
      //       },
      //       {
      //         header: "Project 2",
      //         bullets: [
      //           "100,000 SQFT in Jamuna Future Park",
      //           "৳ 1000 Cr / $100 Million",
      //         ],
      //       },
      //       {
      //         header: "Project 3",
      //         bullets: [
      //           "1Completed Projects on Rent (7 x 10 Stories Apartment Buildings in Dhaka)",
      //         ],
      //       },
      //     ],
      //   },
      // ],
    },
  },
};

export const businessTypeIds = Object.entries(businessTypeDetails);

export default businessTypeDetails;
